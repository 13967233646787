import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { Locale } from 'date-fns';
import { from, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DateFnsLocaleService {
  translocoLocaleService = inject(TranslocoLocaleService);

  locale = toSignal(
    this.translocoLocaleService.localeChanges$.pipe(startWith(this.translocoLocaleService.getLocale()))
  );

  dateFnsLocale: Signal<Locale | undefined> = toSignal(
    from(
      (() => {
        const locale = this.locale();

        switch (locale) {
          case 'pl':
            return import('date-fns/locale/pl').then(module => module.pl);
          case 'en-GB':
          default:
            return import('date-fns/locale/en-GB').then(module => module.enGB);
        }
      })()
    )
  );
}
