import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'bto-logo',
  standalone: true,
  template: `
    <svg class="fill-foreground" viewBox="0 0 180.000000 186.000000" [attr.height]="size()" [attr.width]="size()">
      <g transform="translate(0.000000,186.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M761 1729 c-44 -22 -81 -43 -81 -47 0 -6 72 -49 251 -149 32 -18 136 -78 231 -134 95 -56 178 -102 183 -102 11 0 204 108 218 122 5 5 -27 29 -70 54 -43 25 -168 97 -277 161 -110 64 -216 121 -235 126 -75 21 -133 13 -220 -31z" />
        <path
          d="M385 1519 c-72 -38 -136 -76 -143 -83 -11 -11 1 -22 70 -61 46 -26 92 -54 104 -61 18 -11 28 -9 72 17 28 16 56 29 63 29 6 0 108 -56 226 -125 118 -69 217 -125 220 -125 10 0 223 123 223 129 0 6 -413 250 -532 314 -109 58 -141 54 -303 -34z" />
        <path
          d="M156 992 c-9 -367 -2 -416 73 -495 28 -30 131 -97 150 -97 13 0 16 747 3 766 -5 8 -137 89 -213 130 -4 2 -10 -135 -13 -304z" />
        <path
          d="M1538 1242 l-98 -56 0 -66 0 -66 -213 -122 c-117 -67 -220 -127 -229 -134 -13 -11 -17 -38 -20 -140 -2 -71 0 -128 4 -128 5 0 144 78 310 173 254 145 305 178 327 211 25 38 26 45 29 213 2 95 0 173 -5 172 -4 0 -52 -26 -105 -57z" />
        <path
          d="M490 759 c0 -227 4 -354 11 -380 17 -54 58 -89 202 -174 l127 -74 0 127 0 127 -55 30 -55 29 0 267 0 267 -105 61 c-57 34 -109 61 -115 61 -7 0 -10 -114 -10 -341z" />
        <path
          d="M1313 595 l-331 -190 -4 -133 c-3 -114 -1 -131 12 -127 29 12 526 298 558 322 40 30 90 115 97 168 4 22 5 65 3 95 l-3 56 -332 -191z" />
      </g>
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtoLogoComponent {
  size = input('1em', { transform: (size: number | string) => (typeof size === 'number' ? `${size}em` : size) });
}
