import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { TraceService } from '@sentry/angular';
import * as Sentry from '@sentry/angular';

import { environment } from '@environment';

import { btoErrorHandler } from './error-handler';

export const sentryProviders: Provider[] = [
  {
    provide: ErrorHandler,
    useValue: btoErrorHandler,
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {
      if (environment.sentryDsn) {
        Sentry.init({ dsn: environment.sentryDsn, environment: environment.env });
      }
    },
    deps: [TraceService],
    multi: true,
  },
];
