import { inject } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { log } from '@bto/shared';

export const route = (...segments: string[]) => '/' + segments.join('/');

export const navigate = (router: Router, ...segments: string[]) => {
  const url = route(...segments);
  log(`Navigating to: ${url}`);
  void router.navigate([url]);
};
export const navigateWithOptions = (router: Router, options: NavigationExtras, ...segments: string[]) =>
  void router.navigate([route(...segments)], options);

export const createUrlTree = (...segments: string[]) => inject(Router).createUrlTree([route(...segments)]);

export const getRouteData = <T>(key: string) => inject(Router).getCurrentNavigation()?.extras.state?.[key] as T;

export const getRouteParam = (key: string) => inject(ActivatedRoute).snapshot.params[key] as string;

export const getNumericRouteParam = (key: string) => {
  const maybeNumber = getRouteParam(key);
  return maybeNumber ? +maybeNumber : null;
};
