import { sentryProviders } from '@shared/sentry/sentry.providers';

import { environmentBase } from './environment.base';
import { EnvironmentType } from './environment.type';

export const environment: EnvironmentType = {
  ...environmentBase,
  env: 'staging',
  apiUrl: 'https://api.bto.click/api/v1',
  providers: [sentryProviders],
};
