import {
  Component,
  computed,
  EnvironmentInjector,
  inject,
  input,
  OnInit,
  runInInjectionContext,
  Signal,
  signal,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { BrnProgressImports } from '@spartan-ng/ui-progress-brain';
import { formatDistance } from 'date-fns/formatDistance';

import { DateFnsLocaleService } from '@bto/shared/services/date-fns.locale.service';
import { LoaderService } from '@bto/shared/services/loader.service';
import { Progress, ProgressService } from '@bto/shared/services/progress.service';
import { OperationId } from '@bto/shared/types/loader.types';
import { HlmProgressIndicatorDirective } from '@lib/ui-progress-helm/src';
import { HlmSpinnerComponent, SpinnerVariants } from '@lib/ui-spinner-helm/src';
import { getCurrentDateSignal } from '@shared/utils/date.utils';
import { getInRange } from '@shared/utils/number.utils';

type PreparedProgress = Omit<Progress, 'eta' | 'part'> & { eta: string | null; part: number | null };

@Component({
  selector: 'bto-loader-wrapper',
  templateUrl: './loader-wrapper.component.html',
  standalone: true,
  host: {
    class: 'block w-full relative',
  },
  imports: [BrnProgressImports, HlmProgressIndicatorDirective, HlmSpinnerComponent, TranslocoPipe],
})
export class LoaderWrapperComponent implements OnInit {
  private readonly loaderService = inject(LoaderService);
  private readonly progressService = inject(ProgressService);
  private readonly injector = inject(EnvironmentInjector);
  private readonly dateFnsLocaleService = inject(DateFnsLocaleService);

  operationIds = input(undefined, {
    transform: (value: OperationId | OperationId[] | undefined) =>
      !value ? value : Array.isArray(value) ? value : [value],
  });

  /**
   * Function that translates progress messages under the key `loader.{operationId}`.
   * available params:
   * - `{percentage}` - the percentage of the progress
   * - `{part}` - the current part of the progress
   * - `{numberOfParts}` - the total number of parts
   * - `{eta}` - the estimated time of arrival
   */
  translateFn = input<(key: string, params?: Record<string, any>) => any>();

  size = input<SpinnerVariants['size']>('lg');

  isLoading: Signal<boolean> = signal(false);
  progress: Signal<PreparedProgress | undefined> = signal(undefined);
  percentage = computed(this.calculateProgressForPart.bind(this));

  private currentDate = getCurrentDateSignal();

  ngOnInit() {
    const operationIds = this.operationIds();
    if (!operationIds?.length) {
      return;
    }
    runInInjectionContext(this.injector, () => {
      this.isLoading = this.loaderService.isLoading(...operationIds);
      const progressSignal = this.progressService.get(...operationIds);
      this.progress = computed(() => {
        const locale = this.dateFnsLocaleService.dateFnsLocale();
        const progress = progressSignal();
        if (!progress) {
          return undefined;
        }
        return {
          ...progress,
          part: progress.part ?? null,
          eta: progress.eta ? formatDistance(this.currentDate(), progress.eta, { locale, includeSeconds: true }) : null,
        };
      });
    });
  }

  private calculateProgressForPart() {
    const progress = this.progress();
    if (!progress) return 0;
    const { part, percentage, numberOfParts } = progress;

    if (!numberOfParts || numberOfParts === 1) return percentage;
    const rescaledPercentage = (percentage * (numberOfParts - 1)) / numberOfParts;
    return getInRange(rescaledPercentage + ((part! - 1) / numberOfParts) * 100, 0, 100);
  }
}
