@if (isLoading()) {
  <div
    class="absolute top-0 z-20 bg-background opacity-80 h-full w-full flex flex-col gap-6 items-center justify-center">
    <bto-spinner [size]="size()" />
    @if (translateFn() || progress()) {
      <div class="h-20 w-2/3 max-w-[600px]">
        @if (progress(); as progress) {
          @defer (on immediate) {
            <brn-progress
              class="w-full bg-accent rounded-full h-3 overflow-hidden relative inline-flex"
              [value]="percentage()">
              <brn-progress-indicator bto [value]="percentage()" [animated]="true" />
            </brn-progress>
            <div class="flex justify-between h-4">
              <div>
                @if (progress.eta; as eta) {
                  <p class="leading-none">{{ 'loaders.remaining' | transloco: { eta } }}</p>
                }
              </div>

              <p class="tabular-nums leading-none">
                {{ 'loaders.percentage' | transloco: { percentage: percentage() } }}</p
              >
            </div>
            @if (translateFn(); as translateFn) {
              <p class="text-xl text-center mt-3" [innerHTML]="translateFn('loaders.' + progress.id, progress)"></p>
            }
          }
        }
      </div>
    }
  </div>
}
<ng-content />
