import { loggerStub } from '@shared/utils/logging.utils';

import type { EnvironmentType } from './environment.type';

export const environmentBase: EnvironmentType = {
  env: 'unset',
  apiUrl: 'http://localhost:8000/api/v1',
  ffmpegWasmUrl: 'https://ffmpeg.bto.click',
  sentryDsn: 'https://7926ed818058c1c946e2338384369f03@o4508293340921856.ingest.de.sentry.io/4508297601155153',
  google: {
    clientId: '435992723806-oaet0aj6scskfupcg6k357dg5pgf7aqm.apps.googleusercontent.com',
    scriptUrl: 'https://accounts.google.com/gsi/client',
    scopes: ['openid', 'profile', 'email'],
  },
  apple: {
    clientId: 'fix me',
    scriptUrl: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    scopes: ['name', 'email'],
    disabled: true,
  },
  paddle: {
    environment: 'sandbox',
    token: 'test_458613e019ec5f0b589372826cd',
  },
  interceptors: [],
  providers: [],
  logger: loggerStub,
};
