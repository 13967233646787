/* eslint-disable no-console */

import { btoErrorHandler } from '@shared/sentry/error-handler';

const LOG_STYLES = {
  log: 'color: initial; font-weight: bold;',
  warn: 'color: orange; font-weight: bold;',
  http: 'color: mediumslateblue; font-weight: bold;',
  success: 'color: green; font-weight: bold;',
  error: 'color: red; font-weight: bold;',
  stack: 'color: gray; font-weight: normal;',
  default: 'color: initial; font-weight: bold;', // Default style if type is unknown
};

export const loggerLocal = (() => {
  const logAny = (type: keyof typeof LOG_STYLES, ...args: unknown[]) => {
    console.groupCollapsed(`%c${type.toUpperCase()}: ${args[0] as string}`, LOG_STYLES[type]);
    console.log(...args.slice(1));
    console.trace('TRACE');
    console.groupEnd();
  };

  const logFunction = (...args: unknown[]) => logAny('log', ...args);
  logFunction.http = (...args: unknown[]) => logAny('http', ...args);
  logFunction.warn = (...args: unknown[]) => logAny('warn', ...args);
  logFunction.success = (...args: unknown[]) => logAny('success', ...args);
  logFunction.error = (message: string, cause?: any, ...args: unknown[]) => {
    btoErrorHandler.registerHandledError(message, cause);
    logAny('error', message, cause, ...args);
  };
  logFunction.measure = (id: string, ...args: unknown[]) => {
    logFunction(`Started processing ${id}`, ...args);

    const start = window.performance.now();
    const finish = () => {
      const timeTaken = window.performance.now() - start;
      const timeTakenMs = timeTaken.toFixed(2);
      if (timeTaken > 1000) {
        logFunction.warn(`Finished processing ${id}`, ...args, `Time Taken: ${timeTakenMs} ms`);
        return;
      }
      logFunction.success(`Finished processing ${id}`, ...args, `Time Taken: ${timeTakenMs} ms`);
    };
    const finishWithError = (error: Error) => {
      logFunction.error(`Finished processing ${id} with error`, error, ...args);
    };
    return { finish, finishWithError };
  };

  return logFunction;
})();

export type Logger = typeof loggerLocal;

const doNothing = (...args: any[]) => void 0;

export const loggerStub: Logger = (() => {
  const logFunction = (...args: any[]) => void 0;
  logFunction.http = doNothing;
  logFunction.warn = doNothing;
  logFunction.success = doNothing;
  logFunction.error = (message: string, cause?: any, ...args: unknown[]) => {
    btoErrorHandler.registerHandledError(message, cause);
  };
  logFunction.measure = (id: string, ...args: unknown[]) => ({
    finish: doNothing,
    finishWithError: (error: Error) => {
      logFunction.error(`Finished processing ${id} with error`, error, ...args);
    },
  });

  return logFunction;
})();
