import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, startWith, Subject } from 'rxjs';

import { OperationId } from '@bto/shared/types/loader.types';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private static activeLoaders = new Set<OperationId>();
  private static change = new Subject<void>();

  isLoading(...ids: OperationId[]) {
    return toSignal(
      LoaderService.change.pipe(
        startWith(null),
        map(() => ids.some(id => LoaderService.activeLoaders.has(id)))
      )
    ) as Signal<boolean>;
  }

  static add(id: OperationId) {
    this.activeLoaders.add(id);
    this.change.next();
  }

  static end(id: OperationId) {
    this.activeLoaders.delete(id);
    this.change.next();
  }
}
