import { SentryErrorHandler } from '@sentry/angular';

export class BtoErrorHandler extends SentryErrorHandler {
  constructor() {
    super({ logErrors: false });
  }

  registerHandledError(message: string, cause?: any) {
    this.handleError(new Error(message, { cause: cause instanceof Error ? cause : JSON.stringify(cause) }));
  }
}

export const btoErrorHandler = new BtoErrorHandler();
