import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, startWith, Subject } from 'rxjs';

import { log } from '@bto/shared';
import { OperationId } from '@bto/shared/types/loader.types';

export type ProgressConfig = {
  id: OperationId;
  part?: number;
  numberOfParts?: number;
};

export type Progress = {
  eta?: number;
  percentage: number;
} & ProgressConfig;
export const singleProgress = (id: OperationId): ProgressConfig => ({ id });
export const doubleProgress = (id: OperationId, part: number): ProgressConfig => ({ id, part, numberOfParts: 2 });

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  private static activeProgress = new Map<string, Progress>();
  private static change = new Subject<void>();

  get(...ids: OperationId[]): Signal<Progress | undefined> {
    return toSignal(
      ProgressService.change.pipe(
        startWith(null),
        map(() => ids.find(id => ProgressService.activeProgress.has(id))),
        map(activeId => (activeId ? ProgressService.activeProgress.get(activeId) : undefined))
      )
    );
  }

  static update(
    idOrConfig: ProgressConfig | OperationId,
    progress: (Omit<Progress, 'percentage' | 'id'> & { percentage: string | number }) | null | undefined | number
  ) {
    if (progress == null) return;
    const id = typeof idOrConfig === 'string' ? idOrConfig : idOrConfig.id;
    const config = typeof idOrConfig !== 'string' ? idOrConfig : { id };
    log(`Progress updated ${id}`, progress);
    this.activeProgress.set(
      id,
      typeof progress === 'number'
        ? { ...config, percentage: progress }
        : { ...progress, ...config, percentage: +progress.percentage }
    );
    this.change.next();
  }

  static end(id: string) {
    this.activeProgress.delete(id);
    this.change.next();
  }
}
