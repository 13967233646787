export const ceilToNearest = (number: number, nearest: number): number => {
  return Math.ceil(number / nearest) * nearest;
};

export const setPrecision = (number: number, fractionDigits = 2): number => {
  const factor = Math.pow(10, fractionDigits);
  return Math.round(number * factor) / factor;
};

export const getInRange = (number: number, min: number, max: number) => {
  return Math.min(Math.max(min, number), max);
};

export const isEqual = (float1: number, float2: number, epsilon = 0.001): boolean => {
  return Math.abs(float1 - float2) < epsilon;
};

export const getNumbersInRange = (start: number, end: number, step: number): number[] =>
  Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => setPrecision(start + i * step));
